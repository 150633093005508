const sliderAPI = [
    {
        id: 1,
        classes: "carousel-item active slider",
        imagePath: "./images/cards2k25/1.jpg"
    },
    {
        id: 2,
        classes: "carousel-item slider",
        imagePath: "./images/cards2k25/2.png"
    },
    {
        id: 3,
        classes: "carousel-item slider",
        imagePath: "./images/cards2k25/3.png",
    },
    {
        id: 4,
        classes: "carousel-item slider",
        imagePath: "./images/cards2k25/4.png",
    },
    {
        id: 5,
        classes: "carousel-item slider",
        imagePath: "./images/cards2k25/5.jpg",
    },
];

export default sliderAPI;
