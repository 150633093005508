// SIH 2024 Grand Finale Teams DATA
const SIH2k24GrandFinaleTeamsAPI = [
  {
    _id: "6613cc5a75d100fd8ca988bf",
    id: 1,
    PSID: "SIH2.0-001",
    teamName: "Cassiopeia",
    teamLeaderName: "Damini singh",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c5",
    id: 7,
    PSID: "SIH2.0-007",
    teamName: "CodeWarrior",
    teamLeaderName: "Sultan Alam",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c4",
    id: 6,
    PSID: "SIH2.0-006",
    teamName: "TeamAtharva",
    teamLeaderName: "Sahil Ghonge",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988cb",
    id: 13,
    PSID: "SIH2.0-013",
    teamName: "Team Oriental",
    teamLeaderName: "Aman Jaiswal",
    organization: "Agri-Tech",
    PSCategory: "AG-02",
    problemStatement:
      "User-friendly IoT-enabled Precision Agriculture Platform that facilitates seamless data submission from farmers IoT devices to a cloud platform via API communication and empowers farmers to receive smart results, offering valuable insights for optimizing and enhancing their farming practices.",
    collage: "Oriental Institute of Science and Technology,Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c1",
    id: 3,
    PSID: "SIH2.0-003",
    teamName: "Dev TechFarmSolvers",
    teamLeaderName: "Amit Patel",
    organization: "Agri-Tech",
    PSCategory: "AG-03",
    problemStatement:
      "Farmer AI Platform to optimize crop yields based on specific nutritional demands,  market trends, and sustainable agricultural methods",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988cd",
    id: 15,
    PSID: "SIH2.0-015",
    teamName: "Connect Shiksha",
    teamLeaderName: "Kuldeep Kaware",
    organization: "Edu-Tech",
    PSCategory: "ED-01",
    problemStatement:
      "Inclusive technology-driven system that aims to enhance education in rural areas, focusing on improving the quality of teaching and learning and designed to teach in local languages, foster agricultural literacy, provides a supportive environment for both teachers and students in rural communities.",
    collage: "Bansal Institute of Science and Technology, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c3",
    id: 5,
    PSID: "SIH2.0-005",
    teamName: "Last Minute Logicians",
    teamLeaderName: "Parikshit Singh",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ca",
    id: 12,
    PSID: "SIH2.0-012",
    teamName: "Rural Health Share",
    teamLeaderName: "Ujjawal Shiv",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c0",
    id: 2,
    PSID: "SIH2.0-002",
    teamName: "Team Optimizers",
    teamLeaderName: "Keshav Prajapati",
    organization: "Agri-Tech",
    PSCategory: "AG-02",
    problemStatement:
      "User-friendly IoT-enabled Precision Agriculture Platform that facilitates seamless data submission from farmers IoT devices to a cloud platform via API communication and empowers farmers to receive smart results, offering valuable insights for optimizing and enhancing their farming practices.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c2",
    id: 4,
    PSID: "SIH2.0-004",
    teamName: "Innovators",
    teamLeaderName: "Sumit Kumar Ahirwar",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988cc",
    id: 14,
    PSID: "SIH2.0-014",
    teamName: "Crew",
    teamLeaderName: "Aditya Kotangle",
    organization: "Edu-Tech",
    PSCategory: "ED-01",
    problemStatement:
      "Inclusive technology-driven sysem that aims to enhance education in rural areas, focusing on improving the quality of teaching and learning and designed to teach in local languages, foster agricultural literacy, provides a supportive environment for both teachers and students in rural communities.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c7",
    id: 9,
    PSID: "SIH2.0-009",
    teamName: "TerraSense",
    teamLeaderName: "Rahul Jain",
    organization: "Agri-Tech",
    PSCategory: "AG-03",
    problemStatement:
      "Farmer AI Platform to optimize crop yields based on specific nutritional demands, market trends, and sustainable agricultural methods.",
    collage: "VIT Bhopal University",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c9",
    id: 11,
    PSID: "SIH2.0-011",
    teamName: "Tech Warriors",
    teamLeaderName: "Shalu Jhaa",
    organization: "Agri-Tech",
    PSCategory: "AG-04",
    problemStatement:
      "Innovative Agri Tech Platform: Empowering Sustainable Farming with IoT Sensors",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c6",
    id: 8,
    PSID: "SIH2.0-008",
    teamName: "RuralRemedies",
    teamLeaderName: "yash sharma",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988c8",
    id: 10,
    PSID: "SIH2.0-010",
    teamName: "Herbal Hands",
    teamLeaderName: "Nilesh Nagar",
    organization: "Agri-Tech",
    PSCategory: "AG-01",
    problemStatement:
      "A user-friendly digital platform empowers rural women to share, collaborate, and document homemade remedies for various medical ailments. Serving as a collective repository of traditional health practices, it promotes community-driven healthcare solutions and empowers rural women as vital contributors to their communities well-being.",
    collage: " Lakshmi Narain College of Technology & Science , Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d0",
    id: 18,
    PSID: "SIH2.0-018",
    teamName: "sigma",
    teamLeaderName: "Kunal Rathore",
    organization: "Environment & Climate",
    PSCategory: "EC-01",
    problemStatement:
      "predictive Land Purchase Analysis for Government Agencies",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d5",
    id: 23,
    PSID: "SIH2.0-023",
    teamName: "Titans",
    teamLeaderName: "Pawan Tiwari",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d4",
    id: 22,
    PSID: "SIH2.0-022",
    teamName: "Error 404",
    teamLeaderName: "Dipu Kumar",
    organization: "Health-Tech",
    PSCategory: "HT-05",
    problemStatement:
      "Develop an AI-empowered tool for assessing and mitigating student stress, providing personalized recommendations and coping strategies to enhance overall well-being in educational settings.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d3",
    id: 21,
    PSID: "SIH2.0-021",
    teamName: "UNI-DOC",
    teamLeaderName: "Jiya Singh",
    organization: "Health-Tech",
    PSCategory: "HT-04",
    problemStatement:
      "Development of University Dispensary Management Software",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d2",
    id: 20,
    PSID: "SIH2.0-020",
    teamName: "Wellness Worriors",
    teamLeaderName: "Harsh Rahangdale",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d1",
    id: 19,
    PSID: "SIH2.0-019",
    teamName: "The Matrix Mavericks",
    teamLeaderName: "Yogesh Kumar Soni",
    organization: "Health-Tech",
    PSCategory: "HT-04",
    problemStatement:
      "Development of University Dispensary Management Software",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d6",
    id: 24,
    PSID: "SIH2.0-024",
    teamName: "Shark Tech",
    teamLeaderName: "Shubham Mahobia",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre-identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988db",
    id: 29,
    PSID: "SIH2.0-029",
    teamName: "CodeCrafters",
    teamLeaderName: "Satyam Kumar Gupta",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre-identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988da",
    id: 28,
    PSID: "SIH2.0-028",
    teamName: "Upstart Coders",
    teamLeaderName: "Urvashi Agrawal",
    organization: "Health-Tech",
    PSCategory: "HT-06",
    problemStatement:
      "The current healthcare system has problems with scheduling doctor appointments. Patients struggle to find available slots, while doctors have a hard time managing their schedules and patient records. Admins also find it difficult to oversee everything. We need a new website that can fix these issues by automating scheduling, notifications, and management for everyone involved, making it easier for patients to book appointments, helping doctors manage their schedules, and giving admins better oversight.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988dc",
    id: 30,
    PSID: "SIH2.0-030",
    teamName: "Health Catalyst",
    teamLeaderName: "Pritam Kumar",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d9",
    id: 27,
    PSID: "SIH2.0-027",
    teamName: "Diamond",
    teamLeaderName: "Zoya Khan",
    organization: "Health-Tech",
    PSCategory: "HT 02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d8",
    id: 26,
    PSID: "SIH2.0-026",
    teamName: "Team-Velocity",
    teamLeaderName: "Harsh kamde",
    organization: "Health-Tech",
    PSCategory: "HT-06",
    problemStatement:
      "The current healthcare system has problems with scheduling doctor appointments. Patients struggle to find available slots, while doctors have a hard time managing their schedules and patient records. Admins also find it difficult to oversee everything. We need a new website that can fix these issues by automating scheduling, notifications, and management for everyone involved, making it easier for patients to book appointments, helping doctors manage their schedules, and giving admins better oversight.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988d7",
    id: 25,
    PSID: "SIH2.0-025",
    teamName: "Cool Techie",
    teamLeaderName: "Bhumi Malviya",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre-identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988df",
    id: 33,
    PSID: "SIH2.0-033",
    teamName: "Binary Squad",
    teamLeaderName: "Pari Jain",
    organization: "Health-Tech",
    PSCategory: "HT-04",
    problemStatement: "Development of University Dispensary Management System",
    collage: "Sagar institute of Research and Technology Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988de",
    id: 32,
    PSID: "SIH2.0-032",
    teamName: "BlitzByte",
    teamLeaderName: "Anmol Rajas",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre-identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations.\t",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988dd",
    id: 31,
    PSID: "SIH2.0-031",
    teamName: "ROBOSAPIENS",
    teamLeaderName: "vishal kumar",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations",
    collage: "Lakshmi Narain College of Technology & Science, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e0",
    id: 34,
    PSID: "SIH2.0-034",
    teamName: "Pioneer",
    teamLeaderName: "Ankita Gupta",
    organization: "Health-Tech",
    PSCategory: "HT-06",
    problemStatement:
      "The current healthcare system has problems with scheduling doctor appointments. Patients struggle to find available slots, while doctors have a hard time managing their schedules and patient records. Admins also find it difficult to oversee everything. We need a new website that can fix these issues by automating scheduling, notifications, and management for everyone involved, making it easier for patients to book appointments, helping doctors manage their schedules, and giving admins better oversight.",
    collage: "Jagran Lakecity University , Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e1",
    id: 35,
    PSID: "SIH2.0-035",
    teamName: "Ayurveda",
    teamLeaderName: "Roshni Maran",
    organization: "Health-Tech",
    PSCategory: "HT-02",
    problemStatement:
      "Virtual Hospital platform that leverages technology to offer accessible basic diagnosis, prescription services, and information on pre-identified nearby local clinical facility center and addresses the challenges of limited access to doctors and healthcare facilities, catering to both rural and urban populations.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e2",
    id: 36,
    PSID: "SIH2.0-036",
    teamName: "Breaking Prod",
    teamLeaderName: "Harsh Jain",
    organization: "Health-Tech",
    PSCategory: "HT-05",
    problemStatement:
      " Develop an AI-empowered tool for assessing and mitigating student stress, providing personalized recommendations and coping strategies to enhance overall well-being in educational settings.",
    collage: "Indian Institute of Information Technology Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e3",
    id: 37,
    PSID: "SIH2.0-037",
    teamName: "TechTitans",
    teamLeaderName: "Siddarth Asati",
    organization: "Health-Tech",
    PSCategory: "HT-04",
    problemStatement:
      "Development of University Dispensary Management Software",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e4",
    id: 38,
    PSID: "SIH2.0-038",
    teamName: "BINARY BRAIN",
    teamLeaderName: "SHREYA SINGH",
    organization: "Health-Tech",
    PSCategory: "HT -06",
    problemStatement:
      "The current healthcare system has problems with scheduling doctor appointments. Patients struggle to find available slots, while doctors have a hard time managing their schedules and patient records. Admins also find it difficult to oversee everything. We need a new website that can fix these issues by automating scheduling, notifications, and management for everyone involved, making it easier for patients to book appointments, helping doctors manage their schedules, and giving admins better oversight.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e5",
    id: 39,
    PSID: "SIH2.0-039",
    teamName: "Cyber Safety Squad",
    teamLeaderName: "Utkarsh Dubey",
    organization: "Law & Order",
    PSCategory: "LO-05",
    problemStatement:
      "Public awareness about digital frauds and quick Police access mechanism.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e6",
    id: 40,
    PSID: "SIH2.0-040",
    teamName: "Dev Fnatic Five",
    teamLeaderName: "Tejaswini Dubey",
    organization: "Law & Order",
    PSCategory: "LO-04",
    problemStatement:
      "Create a home automation hardware with real time Integration with firebase and a custom build websites.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e7",
    id: 41,
    PSID: "SIH2.0-041",
    teamName: "Digital Threats",
    teamLeaderName: "Prashant Gour",
    organization: "Law & Order",
    PSCategory: "LO-05",
    problemStatement:
      "Public awareness about digital frauds and quick Police access mechanism.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e9",
    id: 43,
    PSID: "SIH2.0-043",
    teamName: "THE WITCHERS",
    teamLeaderName: "Prateek Khatri",
    organization: "Law & Order",
    PSCategory: "LO-02",
    problemStatement:
      "Integration of Camera Surveillance in toll booths and city with police control room to control vehicle theft",
    collage: "SAGE University Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988e8",
    id: 42,
    PSID: "SIH2.0-042",
    teamName: "Blind Coders",
    teamLeaderName: "Rohit Chaubey",
    organization: "Law & Order",
    PSCategory: "LO-04",
    problemStatement:
      "Create a home automation hardware with real time Integration with firebase and a custom build websites.",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988eb",
    id: 45,
    PSID: "SIH2.0-045",
    teamName: "Team Sensor Squad",
    teamLeaderName: "Harshit Shakya",
    organization: "Law & Order",
    PSCategory: "LO-04",
    problemStatement:
      "Create A Home Automation Hardware with Real Time Integration with Firebase and A Custom Build Websites with An Emergency SOS Button",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ea",
    id: 44,
    PSID: "SIH2.0-044",
    teamName: "HelloWorld",
    teamLeaderName: "Priyanshu Gour",
    organization: "Law & Order",
    PSCategory: "LO-03",
    problemStatement: "\tFace Detection from the blur or unclear image using",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ec",
    id: 46,
    PSID: "SIH2.0-046",
    teamName: "Code Warriors",
    teamLeaderName: "Atul Kumar",
    organization: "Law & Order",
    PSCategory: "LO-03",
    problemStatement: "Face Detection from the blur or unclear image using",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ed",
    id: 47,
    PSID: "SIH2.0-047",
    teamName: "Crew-x",
    teamLeaderName: "Anurag Bisen",
    organization: "Law & Order",
    PSCategory: "LO-03",
    problemStatement: "Face Detection from the blur or unclear image",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ee",
    id: 48,
    PSID: "SIH2.0-048",
    teamName: "TechnoGeeks",
    teamLeaderName: "Ayush Agrawal",
    organization: "Law & Order",
    PSCategory: "LO-05",
    problemStatement:
      "\tPublic awareness about digital frauds and quick Police access mechanism.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ef",
    id: 49,
    PSID: "SIH2.0-049",
    teamName: "Bramhastra",
    teamLeaderName: "Anand Patel",
    organization: "Law & Order",
    PSCategory: "LO-02",
    problemStatement:
      "Integration of Camera Surveillance in toll booths and city with police control room to control vehicle theft.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f0",
    id: 50,
    PSID: "SIH2.0-050",
    teamName: "Web Wizard",
    teamLeaderName: "Aditya",
    organization: "Law & Order",
    PSCategory: "\tLO-05",
    problemStatement:
      "Public awareness about digital frauds and quick Police access mechanism.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f1",
    id: 51,
    PSID: "SIH2.0-051",
    teamName: "Jalageeks",
    teamLeaderName: "Harsh Pawar",
    organization: "Law & Order",
    PSCategory: "LO-04",
    problemStatement:
      "Create a home automation hardware with real time Integration with firebase and a custom build websites.",
    collage: "NRI Institute of Information Science and Technology, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f2",
    id: 52,
    PSID: "SIH2.0-052",
    teamName: "Team Revenant",
    teamLeaderName: "Mohit Yadav",
    organization: "Law & Order",
    PSCategory: "LO-03",
    problemStatement: "Face Detection from the blur or unclear image using",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f3",
    id: 53,
    PSID: "SIH2.0-053",
    teamName: "Innoventure",
    teamLeaderName: "Manoj Kushwaha",
    organization: "Law & Order",
    PSCategory: "LO-07",
    problemStatement: "Smart Traffic Assistance with CORS on Mobiles",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f4",
    id: 54,
    PSID: "SIH2.0-054",
    teamName: "Street Navigators",
    teamLeaderName: "Yashwant Verma",
    organization: "Law & Order",
    PSCategory: "LO-07\t",
    problemStatement: "Smart Traffic Assistance with CORS on Mobiles\t",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f5",
    id: 55,
    PSID: "SIH2.0-055",
    teamName: "SQUAD",
    teamLeaderName: "SHIVAM MALVIYA",
    organization: "Law & Order",
    PSCategory: "LO-05",
    problemStatement:
      "Public awareness about digital frauds and quick Police access mechanism.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f6",
    id: 56,
    PSID: "SIH2.0-056",
    teamName: "Digital Destroyers",
    teamLeaderName: "Jayesh Mahajan",
    organization: "Law & Order",
    PSCategory: "LO-03",
    problemStatement: "Face Detection from the blur or unclear image",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f7",
    id: 57,
    PSID: "SIH2.0-057",
    teamName: "DevRTV",
    teamLeaderName: "Rupesh Rahangdale",
    organization: "Miscellaneous",
    PSCategory: "MC-08",
    problemStatement:
      "Unified Content Management Solution for Government Websites",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f8",
    id: 58,
    PSID: "SIH2.0-058",
    teamName: "Team AAYU",
    teamLeaderName: "Pooja Baghel",
    organization: "Miscellaneous",
    PSCategory: "MC-04",
    problemStatement:
      "Design an Amazon Alexa skills which answers to custom questions and send information to firebase and a website.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988f9",
    id: 59,
    PSID: "SIH2.0-059",
    teamName: "Quantum Quoders",
    teamLeaderName: "Ansh singh",
    organization: "Miscellaneous",
    PSCategory: "MC-01",
    problemStatement:
      "Centralized Management Information System (MIS) for the Department of MP Police",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988fa",
    id: 60,
    PSID: "SIH2.0-060",
    teamName: "RA-5",
    teamLeaderName: "Rahul Kumar",
    organization: "Miscellaneous",
    PSCategory: "MC-04",
    problemStatement:
      "Design an Amazon Alexa skills which answers to custom questions and send information to firebase and a website.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988fb",
    id: 61,
    PSID: "SIH2.0-061",
    teamName: "Cyber Cops",
    teamLeaderName: "Tanya Shekhawat",
    organization: "Miscellaneous",
    PSCategory: "MC-01",
    problemStatement:
      "Centralized Management Information System (MIS) for the Department of MP Police",
    collage: "SAGE University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988fc",
    id: 62,
    PSID: "SIH2.0-062",
    teamName: "Code Crunch",
    teamLeaderName: "Rajul Mewade",
    organization: "Miscellaneous",
    PSCategory: "MC - 03",
    problemStatement:
      "Identification of Hate Speech on various social media platform using Machine  Learning",
    collage: "Sage University, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988fd",
    id: 63,
    PSID: "SIH2.0-063",
    teamName: "The Decoders",
    teamLeaderName: "RITESH BOBADE",
    organization: "Miscellaneous",
    PSCategory: "MC-03",
    problemStatement:
      "Identification of Hate Speech on various social media platform using Machine Learning",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988fe",
    id: 64,
    PSID: "SIH2.0-064",
    teamName: "White Hat Coders",
    teamLeaderName: "Sumit Raj",
    organization: "Miscellaneous",
    PSCategory: "MC-04",
    problemStatement:
      "Design an Amazon Alexa skills which answers to custom questions and send information to firebase and a website.",
    collage: "Lakshmi Narain College Of Technology & Science, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ff",
    id: 65,
    PSID: "SIH2.0-065",
    teamName: "Penta-x",
    teamLeaderName: "Piyush Jain",
    organization: "Miscellaneous",
    PSCategory: "MC-01",
    problemStatement:
      "Centralized Management Information System (MIS) for the Department of MP Police",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca98901",
    id: 67,
    PSID: "SIH2.0-067",
    teamName: "QuadraVision",
    teamLeaderName: "Ishan Shivankar",
    organization: "Miscellaneous",
    PSCategory: "MC-06",
    problemStatement: "Identity Resolution for Government Services",
    collage: "Vishwakarma Institute of Technology, Pune",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca98902",
    id: 68,
    PSID: "SIH2.0-068",
    teamName: "ByteByteGo",
    teamLeaderName: "Ankit Mewada",
    organization: "Security",
    PSCategory: "LO-04",
    problemStatement:
      "Create a home automation hardware with real time Integration with firebase and a custom build websites.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca98900",
    id: 66,
    PSID: "SIH2.0-066",
    teamName: "Team Effizen",
    teamLeaderName: "Aman Yadav",
    organization: "Miscellaneous",
    PSCategory: "MC-01",
    problemStatement:
      "Centralized Management Information System (MIS) for the Department of MP Police",
    collage: "Sagar Institute of Science and Technology Gandhi Nagar, Bhopal ",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca98903",
    id: 69,
    PSID: "SIH2.0-069",
    teamName: "No Cap",
    teamLeaderName: "Khushi Rajput",
    organization: "Urban Mobility",
    PSCategory: "UB-02",
    problemStatement:
      "Police force engagement for seamless periodic force demand and reserve police force deployment through optimum utilization of geographically distributed police reserves.",
    collage: "SAGE University Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca98904",
    id: 70,
    PSID: "SIH2.0-070",
    teamName: "Rachiyata",
    teamLeaderName: "Akshat Tambe",
    organization: "Urban Mobility",
    PSCategory: "UB-02",
    problemStatement:
      "Police force engagement for seamless periodic force demand and reserve police force deployment through optimum utilization of geographically distributed police reserves.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988cf",
    id: 17,
    PSID: "SIH2.0-017",
    teamName: "GreyTech",
    teamLeaderName: "Ayush Kumar",
    organization: "Edu-Tech",
    PSCategory: "ED-01",
    problemStatement:
      "Inclusive technology-driven system that aims to enhance education in rural areas, focusing on improving the quality of teaching and learning and designed to teach in local languages, foster agricultural literacy, provides a supportive environment for both teachers and students in rural communities.",
    collage: "Technocrats Institute of Technology, Bhopal",
    __v: 0,
  },
  {
    _id: "6613cc5a75d100fd8ca988ce",
    id: 16,
    PSID: "SIH2.0-016",
    teamName: "tech coders",
    teamLeaderName: "vivek nagar",
    organization: "Edu-Tech",
    PSCategory: "ED-01",
    problemStatement:
      "Inclusive technology-driven sysem that aims to enhance education in rural areas, focusing on improving the quality of teaching and learning and designed to teach in local languages, foster agricultural literacy, provides a supportive environment for both teachers and students in rural communities.",
    collage: "SISTec, Ratibad Bhopal",
    __v: 0,
  },
];

//  SIH 2024 Winners DATA
const SIH2k24WinnersAPI = [
  {
    id: 1,
    position: 1,
    PSID: "SIH2.0-026",
    teamName: "Team-Velocity",
    teamLeaderName: "Harsh kamde",
    organization: "Health-Tech",
    PSCategory: "HT-06",
    problemStatement:
      "The current healthcare system has problems with scheduling doctor appointments. Patients struggle to find available slots, while doctors have a hard time managing their schedules and patient records. Admins also find it difficult to oversee everything. We need a new website that can fix these issues by automating scheduling, notifications, and management for everyone involved, making it easier for patients to book appointments, helping doctors manage their schedules, and giving admins better oversight.",
    collage: "SISTec, Ratibad Bhopal",
  },
  {
    id: 2,
    position: 1,
    PSID: "SIH2.0-068",
    teamName: "ByteByteGo",
    teamLeaderName: "Ankit Mewada",
    organization: "Security",
    PSCategory: "LO-04",
    problemStatement:
      "Create a home automation hardware with real time Integration with firebase and a custom build websites.",
    collage: "SISTec, Ratibad Bhopal",
  },
  {
    id: 3,
    position: 2,
    PSID: "SIH2.0-017",
    teamName: "GreyTech",
    teamLeaderName: "Ayush Kumar",
    organization: "Edu-Tech",
    PSCategory: "ED-01",
    problemStatement:
      "Inclusive technology-driven system that aims to enhance education in rural areas, focusing on improving the quality of teaching and learning and designed to teach in local languages, foster agricultural literacy, provides a supportive environment for both teachers and students in rural communities.",
    collage: "Technocrats Institute of Technology, Bhopal",
  },
  {
    id: 4,
    position: 3,
    PSID: "SIH2.0-003",
    teamName: "Dev TechFarmSolvers",
    teamLeaderName: "Amit Patel",
    organization: "Agri-Tech",
    PSCategory: "AG-03",
    problemStatement:
      "Farmer AI Platform to optimize crop yields based on specific nutritional demands,  market trends, and sustainable agricultural methods",
    collage: "Sage University, Bhopal",
  },
];

// SIH 2023 Grand Finale Teams DATA
const SIH2k23GrandFinaleTeamsAPI = [
  {
    id: 1,
    PSID: "SM601",
    themeName: "Social Media",
    teamLeaderName: "Aman kumar Kushwaha",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Tracking intial and last IP address, and URL of false photographs , videos, documents or audio posted on the social media platforms.Global Photo video search report. Social Media link, local link, Friend Group Circle. Device Identification.",
    collage: "Jai Narain College of Technology (JNCT)",
  },
  {
    id: 2,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Sudhanshu Yadav",
    organization: " ITSC Ltd",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Sagar Institute of science technology and Research  ",
  },
  {
    id: 3,
    PSID: "CD801",
    themeName: "Criminal Database",
    teamLeaderName: "Anjali Mishra",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Maintaining police station wise data of all thieves,gangsters,vehical thieves, masked/hidden people. Maintaining the data of all criminals along with their previously registered crimes and the actions taken against the crime. ",
    collage: "Sagar Institute of science technology and Research ",
  },
  {
    id: 4,
    PSID: "VT701",
    themeName: "Vehical Theft",
    teamLeaderName: "Shad Warsi",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Identification of hot-spot vehical theft areas with the help of CCTV / City Survillance Camera. Alert message to be generated as soon the theft vehicle crosses a survillance Camera.",
    collage: "Jai Narain College of Technology(JNCT)",
  },
  {
    id: 5,
    PSID: "CD801",
    themeName: "Criminal Database",
    teamLeaderName: "Jagdeep Pandey",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Maintaining police station wise data of all thieves,gangsters,vehical thieves, masked/hidden people. Maintaining the data of all criminals along with their previously registered crimes and the actions taken against the crime.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 6,
    PSID: "CD801",
    themeName: "Criminal Database",
    teamLeaderName: "Ankit Mewada",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Maintaining police station wise data of all thieves,gangsters,vehical thieves, masked/hidden people. Maintaining the data of all criminals along with their previously registered crimes and the actions taken against the crime.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 7,
    PSID: "ET401",
    themeName: "Edu Tech.",
    teamLeaderName: "Harsh Kamde",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "WebAPP like Udemy in which I as instructor can upload courses. The user can buy any course. After they buy through a paymet gateway, they get access to the course and able to watch in an inbuilt video player.. Login  functionality needed to be implemented for both instructor and user. ",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 8,
    PSID: "FE503",
    themeName: "Force Engagement",
    teamLeaderName: "Uma Shankar Mishra",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement: " Daily duty details. Postings and transfers details. ",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 9,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Raja Ahirwar",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 10,
    PSID: "FE501",
    themeName: "Force Engagement",
    teamLeaderName: "Hariom Mewada",
    organization: "MP Police Crime Brach",
    PSCategory: "Software",
    problemStatement:
      "Availability of all posted officers and Police personnels in police stations, status of work assigned with description and Progress. ",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 11,
    PSID: "SM601",
    themeName: "Social Media",
    teamLeaderName: "Deewan Singh Mewada",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Tracking intial and last IP address, and URL of false photographs , videos, documents or audio posted on the social media platforms.Global Photo video search report. Social Media link, local link, Friend Group Circle. Device Identification.",
    collage: "Jai Narain Collage of Technology(JNCT) ",
  },
  {
    id: 12,
    PSID: "HT303",
    themeName: "Health-Tech",
    teamLeaderName: "Bharat Joshi",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "A patient management and video conference APP in which doctor and pateint can communicate on live video, the patient unique data should be stored in the database with each prescription.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 13,
    PSID: "HT303",
    themeName: "Health-Tech",
    teamLeaderName: "Yogesh Kumar Soni",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "A patient management and video conference APP in which doctor and pateint can communicate on live video, the patient unique data should be stored in the database with each prescription.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 14,
    PSID: "CD801",
    themeName: "Criminal Database",
    teamLeaderName: "Roshni Maran",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Maintaining police station wise data of all thieves,gangsters,vehical thieves, masked/hidden people. Maintaining the data of all criminals along with their previously registered crimes and the actions taken against the crime.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 15,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Kulsum Jahan",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 16,
    PSID: "HT301",
    themeName: "Health-Tech",
    teamLeaderName: "Ankush Mishra",
    organization: "ITSC LTD",
    PSCategory: "Software",
    problemStatement:
      "Automatic recognition of Human Disease using radiological data.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 17,
    PSID: "FE503",
    themeName: "Force Engagement",
    teamLeaderName: "Priyanshu Janoriya",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement: "Daily duty details. Postings and transfers details.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 18,
    PSID: "MS202",
    themeName: "Miscellaneous",
    teamLeaderName: "Siddharth Rajput",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "Generate and send PDF or JPG certificate. The App should include a template in which data can be changed. Also, the user will add the list of emails and name of person in the App. App ned to change the name in each certificate and send individual mail to each person with certificate attached.",
    collage: "Jai Narain Collage of Technology(JNCT)",
  },
  {
    id: 19,
    PSID: "HT301",
    themeName: "Health-Tech",
    teamLeaderName: "Gunja Kumari",
    organization: "ITSC LTD",
    PSCategory: "Software",
    problemStatement:
      "Automatic recognition of Human Disease using radiological data.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 20,
    PSID: "FE501",
    themeName: "Force Engagement",
    teamLeaderName: "Amit patel",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Availability of all posted officers and Police personnels in police stations, status of work assigned with description and Progress.",
    collage: "Sage University Bhopal",
  },
  {
    id: 21,
    PSID: "ET401",
    themeName: "Edu-Tech",
    teamLeaderName: "Kuldeep Kamare",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "WebAPP like Udemy in which I as instructor can upload courses. The user can buy any course. After they buy through a paymet gateway, they get access to the course and able to watch in an inbuilt video player.. Login  functionality needed to be implemented for both instructor and user.",
    collage: "Bansal Institute of Science and Technology",
  },
  {
    id: 22,
    PSID: "SM601",
    themeName: "Social Media",
    teamLeaderName: "Pawan Sondhiya",
    organization: "MP police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Tracking intial and last IP address, and URL of false photographs , videos, documents or audio posted on the social media platforms.Global Photo video search report. Social Media link, local link, Friend Group Circle. Device Identification.",
    collage: "Jai Narain Collage of Technology(JNCT)",
  },

  {
    id: 23,
    PSID: "CD801",
    themeName: "Criminal Database",
    teamLeaderName: "Archi Kirar",
    organization: "MP Police Crime Database",
    PSCategory: "Software",
    problemStatement:
      "Maintaining police station wise data of all thieves,gangsters,vehical thieves, masked/hidden people. Maintaining the data of all criminals along with their previously registered crimes and the actions taken against the crime.",
    collage: "Sage University Bhopal",
  },
  {
    id: 24,
    PSID: "SM601",
    themeName: "Social Media",
    teamLeaderName: "Mridul Chaturvedi",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Tracking intial and last IP address, and URL of false photographs , videos, documents or audio posted on the social media platforms.Global Photo video search report. Social Media link, local link, Friend Group Circle. Device Identification.",
    collage: "Jai Narain Collage of Technology(JNCT)",
  },
  {
    id: 25,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Komal Jethwani",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Lakshmi Narain  Collage of Technology",
  },
  {
    id: 26,
    PSID: "VT701",
    themeName: "Vehical Theft",
    teamLeaderName: "Avanish Pathak",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Identification of hot-spot vehical theft areas with the help of CCTV / City Survillance Camera. Alert message to be generated as soon the theft vehicle crosses a survillance Camera.",
    collage: "Jai Narain Collage of Technology(JNCT)",
  },
  {
    id: 27,
    PSID: "ET401",
    themeName: "Edu-Tech",
    teamLeaderName: "Ayush Dewang",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "WebAPP like Udemy in which I as instructor can upload courses. The user can buy any course. After they buy through a paymet gateway, they get access to the course and able to watch in an inbuilt video player.. Login  functionality needed to be implemented for both instructor and user.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 28,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Sohil Saudagar",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Technocrats Institute of Technology ",
  },
  {
    id: 29,
    PSID: "ET401",
    themeName: "Edu-Tech",
    teamLeaderName: "Rythan Asthana",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "WebAPP like Udemy in which I as instructor can upload courses. The user can buy any course. After they buy through a paymet gateway, they get access to the course and able to watch in an inbuilt video player.. Login  functionality needed to be implemented for both instructor and user.",
    collage: "Lakshmi Narain Collage of Technology",
  },
  {
    id: 30,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Kratin Mourya",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Lakshmi Narain Collage of Technology",
  },
  {
    id: 31,
    PSID: "HT303",
    themeName: "Health-Tech",
    teamLeaderName: "Atul Gupta",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "A patient management and video conference APP in which doctor and pateint can communicate on live video, the patient unique data should be stored in the database with each prescription.",
    collage: "Technocrats Institute of Technology ",
  },
  {
    id: 32,
    PSID: "FE503",
    themeName: "Force Engagement",
    teamLeaderName: "Mohit Yadav",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement: "Daily duty details. Postings and transfers details.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 33,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Arpit Singh",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Lakshmi Narain Collage of Technology",
  },
  {
    id: 34,
    PSID: "HT301",
    themeName: "Health-Tech",
    teamLeaderName: "Vanshika Jain",
    organization: "ITSC LTD",
    PSCategory: "Software",
    problemStatement:
      "Automatic recognition of Human Disease using radiological data.",
    collage: "Lakshmi Narain Collage of Technology",
  },
  {
    id: 35,
    PSID: "HT301",
    themeName: "Health-Tech",
    teamLeaderName: "Rupa Singh",
    organization: "ITSC LTD",
    PSCategory: "Software",
    problemStatement:
      "Automatic recognition of Human Disease using radiological data.",
    collage: "Technocrafts Institute of Technology ",
  },
];

//  SIH 2023 Winners DATA
const SIH2k23WinnersAPI = [
  {
    id: 1,
    position: 1,
    PSID: "HT303",
    themeName: "Health-Tech",
    teamLeaderName: "Yogesh Kumar Soni",
    organization: "DRMZ Tech",
    PSCategory: "Software",
    problemStatement:
      "A patient management and video conference APP in which doctor and pateint can communicate on live video, the patient unique data should be stored in the database with each prescription.",
    collage: "Sagar Institute of Science Technology and Research",
  },
  {
    id: 2,
    position: 2,
    PSID: "FE501",
    themeName: "Force Engagement",
    teamLeaderName: "Amit patel",
    organization: "MP Police Crime Branch",
    PSCategory: "Software",
    problemStatement:
      "Availability of all posted officers and Police personnels in police stations, status of work assigned with description and Progress.",
    collage: "Sage University Bhopal",
  },
  {
    id: 3,
    position: 3,
    PSID: "AG102",
    themeName: "Agri-Tech",
    teamLeaderName: "Komal Jethwani",
    organization: "ITSC LTD",
    PSCategory: "Software/Hardware",
    problemStatement:
      "Crop Identification using Machine Learning Model, which will help farmers for better judgement of healthy crop.",
    collage: "Lakshmi Narain  Collage of Technology",
  },
];

export {
  SIH2k24GrandFinaleTeamsAPI,
  SIH2k24WinnersAPI,
  SIH2k23GrandFinaleTeamsAPI,
  SIH2k23WinnersAPI,
};
