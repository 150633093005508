import React from "react";

const SIH_2024_Summary = () => {
  return (
    <>
      <section className="container">
        <div className="container summary">
          <h3>Summary</h3>
          <span>
            In the exhilarating journey of SISTec Innovation Hackathon (SIH
            2.0), over 350 participants joined forces to tackle 29 challenging
            problems across 12 diverse domains. With 70 teams participating, the
            event showcased unparalleled creativity and innovation. The
            hackathon wasn't just about solving problems—it was about fostering
            collaboration, nurturing talent, and building impactful solutions.
            Participants competed intensely over 24 hours, with the top teams
            winning cash prizes of ₹25,000, ₹20,000, and ₹15,000, respectively.
            SIH 2.0 also opened doors to exciting opportunities, including job
            offers, internships, and incubation support. This spirit of
            innovation and learning continues to inspire the next generation of
            innovators.
          </span>
        </div>
      </section>
    </>
  );
};

export default SIH_2024_Summary;
